<template>
  <el-button class="head-btn"
             v-if="buttonControl(item.showFilter, item)"
             v-bind="[item]"
             @click="onclilck(item.clickEvent)">{{item.label}}</el-button>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  methods: {
    onclilck(fun) {
      if (fun && typeof fun === 'function') {
        fun(this.$parent.$parent)
      }
    },
    // 控制按钮显隐
    buttonControl(showFilter, item) {
      if (showFilter && typeof showFilter === 'function') {
        return showFilter(item, this)
      }
      return true
    }
  }
}
</script>
<style scoped>
.head-btn {
  padding: 8px 10px;
  font-size: 14px;
  border-radius: 4px;
}
.test-class {
  margin-left: 30px;
}
</style>
